import { theme } from '@damen/ui'
import styled from 'styled-components'

const ViewToggleWrapper = styled.div`
  justify-content: flex-end;
  width: 100%;
  height: max-content;
  margin-top: ${theme.layout.spacingM};

  @media ${theme.legacyMediaQueries.md} {
    flex-direction: row;
    width: auto;
    margin-top: 0;
  }
`

export { ViewToggleWrapper }
