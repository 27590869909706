import { Tags } from '@/types'

const categories = ['countries', 'market', 'pillar', 'product', 'topic']

interface TagProp {
  key: string
  value: string
}

// Check if this can be reused for Articles and Whitepapers...
export const mapAlgoliaTags = (tags: Tags): TagProp[] => {
  const tagArray: any[] = []
  if (tags) {
    Object.entries(tags).forEach(([key, value]) => {
      if (!categories.includes(key)) {
        return null
      }

      if (Array.isArray(value)) {
        value.forEach((item) => {
          return tagArray.push({ key, value: item?.toString() })
        })
      }

      return null
    })

    return tagArray.flat().filter((tag) => tag.value?.length > 0)
  }

  return null
}
// TODO: Still used in components/ArticleBody and hooks/filter, do we need 2 functions in this tags file?
export const mapTags = (tags: Tags): string[] => {
  const tagArray: any[] = []

  if (tags) {
    Object.entries(tags).forEach(([key, value]) => {
      if (!categories.includes(key)) {
        return null
      }

      return tagArray.push(value)
    })

    return tagArray.flat().filter((tag) => tag)
  }

  return null
}
