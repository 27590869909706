import React from 'react'
import { DataList, theme } from '@damen/ui'
import styled from 'styled-components'
import type { DataListProps } from '@damen/ui/lib/esm/components/DataList/Default'

interface Props {
  price?: string
  specs?: DataListProps[]
}

const ProductQuickViewBody: React.FC<React.PropsWithChildren<Props>> = ({ price, specs }) => (
  <Wrapper>
    {price && <PriceWrapper>{price}</PriceWrapper>}
    {specs && <DataList.Default data={specs} />}
  </Wrapper>
)

const Wrapper = styled.div`
  dl {
    margin-bottom: 0;
  }
`

const PriceWrapper = styled.div`
  font-size: ${theme.typography.fontSizeTextSmall}px;
  line-height: ${theme.typography.lineHeightTextSmall};
  font-weight: bold;
  color: ${theme.colors.blue};
  margin-bottom: ${theme.layout.spacingXL};
  margin-top: ${theme.layout.spacingM};
`

export default ProductQuickViewBody
