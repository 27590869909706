import React, { useMemo } from 'react'
import AlgoliaViewContextProvider from './Context/algoliaViewContext'
import TranslationContextProvider from './Context'
import type { EventOverviewProps } from './types'
import AlgoliaSearchOverview from './default'
import { mapAlgoliaOverviewFilters } from './Helpers/mapAlgoliaOverviewFilters'
import EventCardHit from './Hit/event'
import { FullWidthGapResultsHitWrapper } from './Hits/styles'
import { getTimestampInUtcToday } from '@/utils/dayjsUtils'
import EventCard from '@/skeletons/Cards/Event'

const EventOverviewWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <AlgoliaViewContextProvider>{children}</AlgoliaViewContextProvider>
)

const EventOverviewInner: React.FC<React.PropsWithChildren<EventOverviewProps>> = ({ content }) => {
  const {
    noResultsText,
    noResultsTitle,
    placeholderResults,
    mobileFiltersTitle,
    filters,
    sortByOptions,
    sortByPlaceholder,
  } = content
  const noResultsContent = {
    title: noResultsTitle,
    text: noResultsText,
  }

  const now = getTimestampInUtcToday()

  const mappedSortByOptions = useMemo(
    () =>
      sortByOptions?.map((sort) => {
        return {
          label: sort.label,
          value: sort.option,
        }
      }),
    [sortByOptions],
  )

  return (
    <TranslationContextProvider labels={content.defaultCardLabels}>
      <AlgoliaSearchOverview
        hitWrapperComponent={FullWidthGapResultsHitWrapper}
        hitComponent={EventCardHit}
        skeletonLoader={EventCard}
        facets={mapAlgoliaOverviewFilters(filters)}
        indexName="events"
        resultsPlaceholder={`${placeholderResults}: `}
        mobileFiltersTitle={mobileFiltersTitle}
        noResultsContent={noResultsContent}
        enableSearchbox={false}
        pageSize={8}
        sortByOptions={mappedSortByOptions}
        sortByPlaceholder={sortByPlaceholder}
        initialSort={mappedSortByOptions[0].value}
        defaultSearchOptions={{ filters: `endDateTimestamp > ${now}` }}
      />
    </TranslationContextProvider>
  )
}

const EventOverview: React.FC<React.PropsWithChildren<EventOverviewProps>> = ({ content }) => {
  return (
    <EventOverviewWrapper>
      <EventOverviewInner content={content} />
    </EventOverviewWrapper>
  )
}
export default EventOverview
