import React from 'react'
import { TabControl } from '@damen/ui'
import { ViewToggleWrapper } from './styles'
import { ViewToggleProps } from './types'

const ViewToggle: React.FC<React.PropsWithChildren<ViewToggleProps>> = ({ data }) => {
  return (
    <ViewToggleWrapper>
      <TabControl.Default {...data} lineColor="greyLight" />
    </ViewToggleWrapper>
  )
}

export default ViewToggle
