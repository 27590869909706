import { theme } from '@damen/ui'
import styled from 'styled-components'

const EventImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: ${theme.layout.spacingL};

  @media ${theme.legacyMediaQueries.md} {
    margin-right: ${theme.layout.spacingL};
    margin-bottom: 0;
    max-width: 381px;
  }
`

export { EventImageWrapper }
