import React from 'react'
import { Card, theme } from '@damen/ui'
import Link from '@/components/Link'
import ProductQuickViewBody from './Components/ProductQuickViewBody'
import { HitWrapper } from './types'
import { useTranslationContext } from '../Context'
import { isArrayOfLenghtOne } from '@/utils/arrayUtils'
import { UsedProductIndexItem } from '@/queries/schema.generated'
import { AlgoliaUsedProductOverviewFragment } from '@/queries/fragments/AlgoliaUsedProductOverview.generated'
import { formatCurrencyRange } from '@/utils/helpers/formatCurrency'
import { getSustainableMark } from '@/utils/helpers/sustainableIcon'
import { ProductSpecification } from '../types'

const UsedProductHit: React.FC<React.PropsWithChildren<HitWrapper>> = (hitWrapper) => {
  const { hit } = hitWrapper

  const {
    image,
    url: formattedSlug,
    name: title,
    labels,
    priceRange,
    specificationsFormatted,
    thumbnail,
    sustainableMark,
  } = hit as unknown as UsedProductIndexItem & Record<string, any>

  const context = useTranslationContext()
  const topFourSpecifications = Object.values(specificationsFormatted)
    .slice(0, 4)
    // @ts-expect-error TODO Fix this type issue
    .map((specification: ProductSpecification) => ({
      id: specification.id,
      label: specification.unitSymbol ? `${specification.name} (${specification.unitSymbol})` : specification.name,
      value: specification.value ? specification.value.toString() : '-',
    }))
  const defaultCardLabels: AlgoliaUsedProductOverviewFragment['defaultCardLabels'] = context.labels
  const priceCategory = {
    id: topFourSpecifications.length + 1,
    label: defaultCardLabels?.priceCategory,
    value: formatCurrencyRange(priceRange, ''),
  }

  const mappedSpecifications = [...topFourSpecifications, priceCategory]

  const buttonComponent = formattedSlug
    ? ({ children }: any) => (
        <Link.VNext type="link" link={formattedSlug}>
          {children}
        </Link.VNext>
      )
    : null

  const productLabel = isArrayOfLenghtOne(labels) ? labels[0] : null

  const titleWithIcon = () => (
    <>
      {title}
      {getSustainableMark(sustainableMark.icon)}
    </>
  )

  return (
    <Card.ProductQuickViewCard
      title={sustainableMark ? titleWithIcon() : title}
      buttonComponent={buttonComponent}
      imageLabel={productLabel?.title}
      imageLabelColor={productLabel?.color}
      image={{
        src: thumbnail ?? image,
        aspectRatio: '376:272',
        sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
      }}
    >
      <ProductQuickViewBody specs={mappedSpecifications} />
    </Card.ProductQuickViewCard>
  )
}

export default UsedProductHit
