import React from 'react'
import { DataList } from '@damen/ui'
import styled from 'styled-components'
import type { DataListProps } from '@damen/ui/lib/esm/components/DataList/Default'

interface Props {
  datalistItems?: DataListProps[]
}

const ShipreairYardBody: React.FC<React.PropsWithChildren<Props>> = ({ datalistItems }) => (
  <Wrapper>{datalistItems && <DataList.Default data={datalistItems} compact />}</Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;

  dl {
    margin-bottom: 0;
  }
`

export default ShipreairYardBody
