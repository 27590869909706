import React from 'react'
import { Card, theme } from '@damen/ui'
import Link from '@/components/Link'
import { HitWrapper } from './types'
import { LocationIndexItem } from '@/queries/schema.generated'
import { AlgoliaShiprepairYardOverviewFragment } from '@/queries/fragments/AlgoliaShiprepairYardOverview.generated'
import ShiprepairYardBody from './Components/ShiprepairYardBody'
import { useTranslationContext } from '../Context'
import { useAlgoliaViewContext } from '../Context/algoliaViewContext'

const LocationHit: React.FC<React.PropsWithChildren<HitWrapper>> = (hitWrapper) => {
  const { hit } = hitWrapper
  const context = useTranslationContext()
  const algoliaContext = useAlgoliaViewContext()

  const {
    url: formattedSlug,
    name: title,
    image,
    country,
    longitude,
    latitude,
    maxLength,
    mapsImage,
  } = hit as unknown as LocationIndexItem & Record<string, any>

  // eslint-disable-next-line prefer-destructuring
  const labels: AlgoliaShiprepairYardOverviewFragment['defaultCardLabels'] = context.labels

  const buttonComponent = formattedSlug
    ? ({ children }: any) => (
        <Link.VNext type="link" link={formattedSlug}>
          {children}
        </Link.VNext>
      )
    : undefined

  const coordinates = `${latitude} / ${longitude}`
  const defaultImage = {
    src: image,
    aspectRatio: '376:272',
    sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
  }
  const mapImage = {
    src: mapsImage,
    aspectRatio: '376:272',
    sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
  }

  const imageSource = () => {
    switch (algoliaContext.viewMode) {
      case 'map':
        return mapImage

      case 'list':
      default:
        return defaultImage
    }
  }

  return (
    <Card.Basic title={title} buttonComponent={buttonComponent} image={imageSource()}>
      <ShiprepairYardBody
        datalistItems={[
          {
            id: `${hit.id}-${title}-${country}`,
            label: labels.country,
            value: country,
          },
          {
            id: `${hit.id}-${title}-${maxLength}`,
            label: labels.maxLength,
            value: maxLength ? `${maxLength} m` : '',
          },
          {
            id: `${hit.id}-${title}-${coordinates}`,
            label: labels.coordinates,
            value: coordinates,
          },
        ]}
      />
    </Card.Basic>
  )
}

export default LocationHit
