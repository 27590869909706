import { theme } from '@damen/ui'
import styled from 'styled-components'

const ProductImageWrapper = styled.div`
  height: 35px;
  max-width: 48px;
  margin-right: 20px;
  width: 100%;

  @media ${theme.legacyMediaQueries.lg} {
    display: block;
    height: 121px;
    max-width: 168px;
    margin-right: 40px;
  }
`

const ProductCardFooter = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: ${theme.layout.spacingL};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -24px;
    height: 1px;
    width: calc(100% + 48px);
    background-color: ${theme.colors.greyAccentLight};

    @media ${theme.legacyMediaQueries.lg} {
      width: calc(100% + 50px);
      left: -25px;
    }
  }

  @media ${theme.legacyMediaQueries.lg} {
    justify-content: flex-end;
    padding-top: ${theme.layout.spacingXL};
  }
`

const ProductCardTag = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const ProductCardHighlight = styled.div`
  margin: 0 70px 8px 0;

  &:last-child {
    margin-right: 0;
  }

  @media ${theme.legacyMediaQueries.mdMax} {
    margin: 0;
    max-width: 50%;
    width: 100%;

    &:nth-child(odd) {
      padding-right: ${theme.layout.spacing};
    }

    &:nth-child(even) {
      padding-left: ${theme.layout.spacing};
    }
  }

  @media ${theme.legacyMediaQueries.lgMax} {
    margin-top: ${theme.layout.spacingSM};
  }
`

// Account for image and tag width
const ProductContentWrapper = styled.div`
  width: 100%;

  @media ${theme.legacyMediaQueries.lg} {
    width: calc(100% - 301px);
  }
`

export { ProductImageWrapper, ProductCardFooter, ProductCardTag, ProductContentWrapper, ProductCardHighlight }
