import React from 'react'
import AlgoliaSearchOverview from './default'
import { mapAlgoliaOverviewFilters } from './Helpers/mapAlgoliaOverviewFilters'
import DefaultHit from './Hit/default'
import { ArticleOverviewProps } from './types'
import MediaCard from '@/skeletons/Cards/media'

const ArticlesAlgoliaSearchOverview: React.FC<React.PropsWithChildren<ArticleOverviewProps>> = ({ content }) => {
  const { noResultsText, noResultsTitle, placeholderResults, mobileFiltersTitle, placeholderSearch, filters } = content
  const noResultsContent = {
    title: noResultsTitle,
    text: noResultsText,
  }

  return (
    <AlgoliaSearchOverview
      hitComponent={DefaultHit}
      skeletonLoader={MediaCard}
      facets={mapAlgoliaOverviewFilters(filters)}
      indexName="articles"
      mobileFiltersTitle={mobileFiltersTitle}
      searchBoxPlaceholder={placeholderSearch}
      resultsPlaceholder={`${placeholderResults}: `}
      noResultsContent={noResultsContent}
      defaultSearchOptions={{ filters: 'type:article' }}
    />
  )
}
export default ArticlesAlgoliaSearchOverview
