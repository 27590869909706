import React, { createContext, useContext, useMemo, useState } from 'react'

type OverviewViewViewProps = 'list' | 'map'

interface AlgoliaContextProps {
  setViewMode: React.Dispatch<React.SetStateAction<OverviewViewViewProps>>
  viewMode: OverviewViewViewProps
}

const AlgoliaContext = createContext<AlgoliaContextProps>(null)

export interface AlgoliaViewContextProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

const AlgoliaViewContextProvider = ({ children }: AlgoliaViewContextProviderProps) => {
  const [viewMode, setViewMode] = useState<OverviewViewViewProps>('list')
  const context = useMemo(() => {
    return {
      viewMode,
      setViewMode,
    }
  }, [viewMode])
  return <AlgoliaContext.Provider value={context}>{children}</AlgoliaContext.Provider>
}

export const useAlgoliaViewContext = () => {
  return useContext(AlgoliaContext)
}

export default AlgoliaViewContextProvider
