import React from 'react'

import { SkeletonLoader } from '@/components/UI'
import { Card, CardContent, ColumnWrapper, FlexWrapWrapper, RowWrapper, TabletHidden } from '../../styles'
import TitleSkeleton from '../../Title'
import Spacer from '../../Spacer'
import Button from '../../Button'
import {
  ProductImageWrapper,
  ProductCardFooter,
  ProductCardTag,
  ProductContentWrapper,
  ProductCardHighlight,
} from './styles'

const highlights = 5

const ProductCard = () => (
  <Spacer bottomMargin={24}>
    <Card>
      <CardContent>
        <Spacer bottomMargin={24}>
          <RowWrapper>
            <ProductImageWrapper>
              <SkeletonLoader width="100%" height="100%" borderRadius={0} />
            </ProductImageWrapper>
            <ProductContentWrapper>
              <ColumnWrapper>
                <TitleSkeleton size="default" marginBottom="small" />
                <FlexWrapWrapper>
                  {new Array<number>(highlights).map((_item, index) => {
                    const randomId = Math.random()
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <ProductCardHighlight key={`${randomId}highlight${index}`}>
                        <ColumnWrapper>
                          <Spacer bottomMargin={4}>
                            <SkeletonLoader width="93px" height="32px" />
                          </Spacer>
                          <div>
                            <SkeletonLoader width="93px" height="16px" />
                          </div>
                        </ColumnWrapper>
                      </ProductCardHighlight>
                    )
                  })}
                </FlexWrapWrapper>
                <Spacer bottomMargin={4} topMargin={24}>
                  <SkeletonLoader width="100%" height="16px" />
                </Spacer>
                <Spacer bottomMargin={4}>
                  <SkeletonLoader width="100%" height="16px" />
                </Spacer>
                <SkeletonLoader width="100%" height="16px" />
              </ColumnWrapper>
            </ProductContentWrapper>
            <TabletHidden>
              <ProductCardTag>
                <SkeletonLoader width="70px" height="32px" />
              </ProductCardTag>
            </TabletHidden>
          </RowWrapper>
        </Spacer>
        <ProductCardFooter>
          <Spacer rightMargin={16} fullWidth={false}>
            <Button />
          </Spacer>
          <Button />
        </ProductCardFooter>
      </CardContent>
    </Card>
  </Spacer>
)

export default ProductCard
