import React from 'react'
import { Card, theme } from '@damen/ui'
import Link from '@/components/Link'
import { HitWrapper } from './types'
import { ProjectIndexItem } from '@/queries/schema.generated'
import { AlgoliaProjectOverviewFragment } from '@/queries/fragments/AlgoliaProjectOverview.generated'
import ProductQuickViewBody from './Components/ProductQuickViewBody'
import { useTranslationContext } from '../Context'

const UsedProjectHit: React.FC<React.PropsWithChildren<HitWrapper>> = (hitWrapper) => {
  const { hit } = hitWrapper
  const context = useTranslationContext()

  const {
    url: formattedSlug,
    name: title,
    thumbnail,
    location,
    services,
    date,
    type,
    contractor,
  } = hit as unknown as ProjectIndexItem & Record<string, any>

  // eslint-disable-next-line prefer-destructuring
  const labels: AlgoliaProjectOverviewFragment['defaultCardLabels'] = context.labels

  const buttonComponent = formattedSlug
    ? ({ children }: any) => (
        <Link.VNext type="link" link={formattedSlug}>
          {children}
        </Link.VNext>
      )
    : null

  const formattedServices = services?.length > 1 ? services.join(', ') : services

  const projectData = () => {
    switch (type) {
      case 'dredging':
        return [
          labels.contractorText &&
            contractor && {
              id: `${hit.id}-${title}-${contractor}`,
              label: labels.contractorText,
              value: contractor,
            },
          labels.shipyardText &&
            location && {
              id: `${hit.id}-${title}-${location.id}`,
              label: labels.shipyardText,
              value: location,
            },
          labels.periodText &&
            date && {
              id: `${hit.id}-${title}-${date}`,
              label: labels.periodText,
              value: date,
            },
        ]

      case 'shiprepair':
      default:
        return [
          labels.periodText &&
            date && {
              id: `${hit.id}-${title}-${date}`,
              label: labels.periodText,
              value: date,
            },
          labels.shipyardText &&
            location && {
              id: `${hit.id}-${title}-${location.id}`,
              label: labels.shipyardText,
              value: location,
            },
          labels.servicesText &&
            formattedServices && {
              id: `${hit.id}-${title}-${services}`,
              label: labels.servicesText,
              value: formattedServices,
            },
        ]
    }
  }

  return (
    <Card.ProductQuickViewCard
      title={title}
      buttonComponent={buttonComponent}
      image={{
        src: thumbnail,
        sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
      }}
    >
      <ProductQuickViewBody specs={projectData().filter((x) => x !== undefined && (x as unknown) !== '')} />
    </Card.ProductQuickViewCard>
  )
}

export default UsedProjectHit
