import React from 'react'
import AlgoliaSearchOverview from './default'
import { mapAlgoliaOverviewFilters } from './Helpers/mapAlgoliaOverviewFilters'
import DefaultHit from './Hit/default'
import { NewsOverviewProps } from './types'
import MediaCard from '@/skeletons/Cards/media'

const NewsAlgoliaSearchOverview: React.FC<React.PropsWithChildren<NewsOverviewProps>> = ({ content }) => {
  const { noResultsText, noResultsTitle, placeholderResults, mobileFiltersTitle, placeholderSearch, filters } = content
  const noResultsContent = {
    title: noResultsTitle,
    text: noResultsText,
  }

  return (
    <AlgoliaSearchOverview
      hitComponent={DefaultHit}
      skeletonLoader={MediaCard}
      facets={mapAlgoliaOverviewFilters(filters)}
      indexName="articles"
      searchBoxPlaceholder={placeholderSearch}
      resultsPlaceholder={`${placeholderResults}: `}
      mobileFiltersTitle={mobileFiltersTitle}
      noResultsContent={noResultsContent}
      defaultSearchOptions={{ filters: 'type:news' }}
    />
  )
}
export default NewsAlgoliaSearchOverview
