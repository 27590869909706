import { Media, theme } from '@damen/ui'
import React from 'react'
import { HitWrapper } from './types'
import Link from '@/components/Link'

const DefaultHit: React.FC<React.PropsWithChildren<HitWrapper>> = (hitWrapper) => {
  const { id, hit } = hitWrapper
  // TODO: add Algolia typings to FED
  const { image: imageUrl, slug: formattedSlug, title, formattedCreatedAt, label } = hit as any

  const image = {
    src: imageUrl,
    sizes: `${theme.legacyMediaQueries.lg} calc((100vw - 240px) / 3), ${theme.legacyMediaQueries.md} calc((100vw - 160px) / 2), 100vw`,
  }

  const buttonComponent = formattedSlug
    ? ({ children }: any) => (
        <Link.VNext type="link" link={formattedSlug}>
          {children}
        </Link.VNext>
      )
    : null

  return (
    <Media.Card
      key={id}
      title={title}
      buttonComponent={buttonComponent}
      link={null}
      label={formattedCreatedAt}
      imageLabel={label}
      image={image}
    />
  )
}

export default DefaultHit
