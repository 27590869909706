import React from 'react'
import { Icon } from '@damen/ui'
import router from 'next/router'
import styled from 'styled-components'

type ShareLinkType = 'facebook' | 'linkedIn' | 'mail' | 'X'

const getShareLink = (type: ShareLinkType, emailSubject?: string) => {
  let link = ''

  if (typeof window !== 'undefined') {
    link = encodeURIComponent(`${window.location.origin}${router.asPath}`)
  }

  switch (type) {
    case 'facebook':
      return `https://facebook.com/sharer/sharer.php?u=${link}`

    case 'linkedIn':
      return `https://www.linkedin.com/sharing/share-offsite/?url=${link}`

    case 'mail': {
      const body = encodeURIComponent('')

      return `mailto:?subject=${emailSubject || ''}&body=${(body && `${body}\n\n${link}`) || link}`
    }

    case 'X': {
      let title = ''

      if (typeof document !== 'undefined') {
        title = document.querySelector('h1')?.innerText
      }

      return `https://twitter.com/intent/tweet?text=${title ? `${encodeURIComponent(title)} #Damen` : ''}&url=${link}`
    }

    default:
      return null
  }
}

const RenderIcon = (type: ShareLinkType, emailSubject?: string): React.ComponentType<React.PropsWithChildren<any>> => {
  const ShareIcon = () => {
    const dimensions = 18
    const XDimensions = 15
    switch (type) {
      case 'X':
        return <Icon.X width={XDimensions} height={XDimensions} fill="grey" />

      case 'facebook':
        return <Icon.Facebook width={dimensions} height={dimensions} fill="grey" />

      case 'linkedIn':
        return <Icon.LinkedIn width={dimensions} height={dimensions} fill="grey" />

      case 'mail':
        return <Icon.Envelope width={dimensions} height={dimensions} fill="grey" />

      default:
        return null
    }
  }

  const SocialLink = (props: any) => (
    <Link href={getShareLink(type, emailSubject)} rel="noopener" target="_blank">
      <ShareIcon {...props} />
    </Link>
  )

  return SocialLink
}

export const socialIcons = [
  {
    icon: RenderIcon('X'),
    title: 'X',
  },
  {
    icon: RenderIcon('facebook'),
    title: 'Facebook',
  },
  {
    icon: RenderIcon('linkedIn'),
    title: 'LinkedIn',
  },
  {
    icon: RenderIcon('mail'),
    title: 'Mail',
  },
]

const Link = styled.a`
  cursor: pointer;

  &:focus,
  &:hover {
    svg {
      fill: ${({ theme }) => theme.colors.blue};
      g,
      path {
        fill: ${({ theme }) => theme.colors.blue};
      }
    }
  }
`
