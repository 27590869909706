import React, { useMemo } from 'react'
import TranslationContextProvider from './Context'
import AlgoliaSearchOverview from './default'
import { mapAlgoliaOverviewFilters } from './Helpers/mapAlgoliaOverviewFilters'
import UsedProductHit from './Hit/usedProduct'
import { UsedProductsOverviewProps } from './types'
import UsedProductCard from '@/skeletons/Cards/usedProduct'

const AlgoliaUsedProductsOverview: React.FC<React.PropsWithChildren<UsedProductsOverviewProps>> = ({ content }) => {
  const {
    noResultsText,
    placeholderResults,
    mobileFiltersTitle,
    noResultsTitle,
    filters,
    title,
    sortByOptions,
    sortByOptions4thSpecification,
    defaultCardLabels,
    sortByPlaceholder,
    totalResultsText,
  } = content
  const noResultsContent = {
    title: noResultsTitle,
    text: noResultsText,
  }

  const mappedsortByOptions4thSpecification = useMemo(
    () =>
      sortByOptions4thSpecification?.map((sort) => {
        return {
          label: sort.label,
          value: sort.option,
          ranges: sort?.ranges?.map((range) => {
            return { name: range.name }
          }),
        }
      }),
    [sortByOptions4thSpecification],
  )

  const mappedSortByOptions = useMemo(
    () =>
      sortByOptions?.map((sort) => {
        return {
          label: sort.label,
          value: sort.option,
        }
      }),
    [sortByOptions],
  )

  return (
    <TranslationContextProvider labels={defaultCardLabels}>
      <AlgoliaSearchOverview
        hitComponent={UsedProductHit}
        skeletonLoader={UsedProductCard}
        facets={mapAlgoliaOverviewFilters(filters)}
        indexName="usedproducts"
        noResultsContent={noResultsContent}
        enableSearchbox={false}
        pageSize={18}
        title={{ title, totalResultsText }}
        resultsPlaceholder={`${placeholderResults}: `}
        mobileFiltersTitle={mobileFiltersTitle}
        sortByOptions={mappedSortByOptions}
        sortByOptions4thSpecification={mappedsortByOptions4thSpecification}
        sortByPlaceholder={sortByPlaceholder}
      />
    </TranslationContextProvider>
  )
}
export default AlgoliaUsedProductsOverview
