import React from 'react'
import TranslationContextProvider from './Context'
import AlgoliaSearchOverview from './default'
import { mapAlgoliaOverviewFilters } from './Helpers/mapAlgoliaOverviewFilters'
import ProjectHit from './Hit/project'
import { ProjectOverviewProps } from './types'
import BasicCard from '@/skeletons/Cards/basic'

const AlgoliaProjectOverview: React.FC<React.PropsWithChildren<ProjectOverviewProps>> = ({ content }) => {
  const { noResultsText, noResultsTitle, filters, title = '', projectCategory } = content
  const noResultsContent = {
    title: noResultsTitle,
    text: noResultsText,
  }

  return (
    <TranslationContextProvider labels={content.defaultCardLabels}>
      <AlgoliaSearchOverview
        hitComponent={ProjectHit}
        skeletonLoader={BasicCard}
        facets={mapAlgoliaOverviewFilters(filters)}
        indexName="projects"
        noResultsContent={noResultsContent}
        enableSearchbox={false}
        pageSize={18}
        title={{ title }}
        defaultSearchOptions={{ filters: `type:"${projectCategory}"` }}
      />
    </TranslationContextProvider>
  )
}
export default AlgoliaProjectOverview
