import React, { useEffect, useMemo, useState } from 'react'
import { Article } from '@damen/ui'
import styled from 'styled-components'

import { socialIcons } from '@/components/Social/Icons'
import { Container, TagLabels } from '@/components/UI'
import { mapTags } from '@/utils/helpers'
import { mapAlgoliaTags } from '@/utils/helpers/tags'
import SocialShare from '../SocialShare'
import { BlokZone } from '../UI/BlokZone'
import Components from '@/components/Components'
import { removePrecedingSlash } from '@/utils/slugUtils'

interface Props {
  content: any
  details: {
    author?: string
    readTime?: string
    socials: []
  }
}

const ArticleBody: React.FC<React.PropsWithChildren<Props>> = ({ content }) => {
  const mappedTags = useMemo(() => {
    if (content?.tags) {
      switch (content.__typename) {
        case 'ArticleBody':
        case 'NewsBody':
          return mapAlgoliaTags(content.tags).map((tag) => {
            // Articles & News now uses Algolia and have been merged in the Articles index
            if (tag.value?.length > 0) {
              return {
                label: tag.value,
                // This was only added as a workaround for hotfix
                // TODO: cachedUrl should no longer be used
                link: `/${removePrecedingSlash(
                  content.breadcrumbLink.cachedUrl,
                )}?articles%5BrefinementList%5D%5Btags.${encodeURIComponent(tag.key)}%5D%5B0%5D=${encodeURIComponent(
                  tag.value,
                )}`,
              }
            }
            return null
          })

        default:
          return mapTags(content.tags).map((tag) => {
            return {
              label: tag,
              // This was only added as a workaround for hotfix
              // TODO: cachedUrl should no longer be used
              link: `/${removePrecedingSlash(content.breadcrumbLink.cachedUrl)}?f=${encodeURIComponent(tag)}`,
            }
          })
      }
    }
    return null
  }, [content])
  const [finalSocialIcons, setFinalSocialIcons] = useState(null)

  useEffect(() => {
    setFinalSocialIcons(socialIcons)
  }, [])

  return (
    <>
      <BlokZone
        bloks={content?.content}
        componentLookup={Components}
        additionalProps={{
          maxWidth: 792,
          pageType: 'article-detail',
        }}
      />
      {content?.details && (
        <Container>
          <DetailsOuter>
            <hr />
            <DetailsRow>
              {finalSocialIcons && (
                <Article.Details
                  author={content.details.author}
                  readTime={content.details.readTime}
                  shareComponent={() => <SocialShare />}
                />
              )}
            </DetailsRow>
            {content?.tags && <TagLabels tags={mappedTags} />}
          </DetailsOuter>
        </Container>
      )}
    </>
  )
}

const DetailsOuter = styled.div`
  max-width: ${({ theme }) => theme.layout.textContainerWidth};
  margin: 0 auto;

  hr {
    width: 100%;
    margin: 0 0 48px;
    border: solid 1px ${({ theme }) => theme.colors.greyAccentLight};
  }
`

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export default ArticleBody
