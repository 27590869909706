import React from 'react'
import { Card, theme } from '@damen/ui'
import Link from '@/components/Link'
import { HitWrapper } from './types'
import { useTranslationContext } from '../Context'
import { EventIndexItem } from '@/queries/schema.generated'
import { AlgoliaEventOverviewFragment } from '@/queries/fragments/AlgoliaEventOverview.generated'
import { isExternalUrl } from '@/utils/uriUtil'
import { renderRichText } from '@/utils/richTextUtils'

const PageLink = (url: string, internalText: string, externalText: string) => {
  if (isExternalUrl(url)) return <Link.VNext text={externalText} type="contentlink" link={url} />
  return <Link.VNext text={internalText} type="button" link={url} />
}

const Hit: React.FC<React.PropsWithChildren<HitWrapper>> = (hitWrapper) => {
  const { hit } = hitWrapper
  const context = useTranslationContext()

  const {
    image,
    url,
    name: title,
    city,
    country,
    markets,
    formattedDate,
    description,
    id,
  } = hit as unknown as EventIndexItem & Record<string, any>

  // eslint-disable-next-line prefer-destructuring
  const labels: AlgoliaEventOverviewFragment['defaultCardLabels'] = context.labels
  const formattedLocation = () => {
    if (city && country) return `${city}, ${country}`
    if (!city && country) return country

    return null
  }
  const formattedMarkets = markets.join(', ')
  const formattedDescription = renderRichText(JSON.parse(description), 'plain')

  const eventDetails = [
    {
      id: `${id}date`,
      label: labels.dateText,
      value: formattedDate,
    },
    {
      id: `${id}location`,
      label: labels.locationText,
      value: formattedLocation(),
    },
    {
      id: `${id}markets`,
      label: labels.marketsText,
      value: formattedMarkets,
    },
  ]

  return (
    <Card.EventCard
      linkComponent={() => (url ? PageLink(url, labels.internalPageText, labels.externalPageText) : null)}
      description={formattedDescription}
      title={title}
      details={eventDetails}
      // No link for now passed in the itemLinkComponent as we currently never make the title and image clickable
      itemLinkComponent={({ children }) => <>{children}</>}
      image={{
        src: image,
        sizes: `${theme.legacyMediaQueries.md} 381px, 100vw`,
      }}
    />
  )
}

export default Hit
