import React, { createContext, useContext, useMemo } from 'react'

type TranslateFunc = (type: string, value: string) => string
interface TranslationContextProps {
  translate: TranslateFunc
  labels?: Record<string, string>
}

const TranslationContext = createContext<TranslationContextProps>(null)

export interface TranslationContextProviderProps {
  translate?: TranslateFunc
  labels?: Record<string, string>
  children: React.ReactNode | React.ReactNode[] | null
}

const TranslationContextProvider = ({ children, translate, labels }: TranslationContextProviderProps) => {
  const context = useMemo(() => {
    return {
      translate,
      labels,
    }
  }, [translate, labels])
  return <TranslationContext.Provider value={context}>{children}</TranslationContext.Provider>
}

export const useTranslationContext = () => {
  return useContext(TranslationContext)
}

export default TranslationContextProvider
