import React, { createContext, useContext, useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import { DownloadModalProps } from '@/components/UI/Modal/types'
import { ModalDownloadFragment } from '@/queries/fragments/Modal/Download.generated'

const DownloadModal = dynamic(() => import('@/components/Modal/Download'), { ssr: false })

interface ModalDownloadContextProps {
  modalDownloadData: ModalDownloadFragment
  setModal: React.Dispatch<React.SetStateAction<DownloadModalProps>>
}

const ModalDownloadContext = createContext<ModalDownloadContextProps>(null)

export interface ModalDownloadContextProviderProps {
  modalDownloadData: ModalDownloadFragment
  children: React.ReactNode | React.ReactNode[] | null
}

const ModalDownloadContextProvider = ({ children, modalDownloadData }: ModalDownloadContextProviderProps) => {
  const [modal, setModal] = useState<DownloadModalProps>(null)
  const context = useMemo(() => {
    return {
      modalDownloadData,
      setModal,
    }
  }, [modalDownloadData, setModal])

  if (!modalDownloadData) {
    // Fragment needed as wrapper for the provider
    return <>{children}</>
  }

  return (
    <ModalDownloadContext.Provider value={context}>
      {children}
      <DownloadModal
        selectedModal={modal}
        handleCloseModal={() => setModal(null)}
        modalDownload={modalDownloadData.content}
      />
    </ModalDownloadContext.Provider>
  )
}

export const useModalDownloadContext = () => {
  return useContext(ModalDownloadContext)
}

export default ModalDownloadContextProvider
