import React from 'react'
import AlgoliaSearchOverview from './default'
import { mapAlgoliaOverviewFilters } from './Helpers/mapAlgoliaOverviewFilters'
import ProductCardHit from './Hit/product'
import { FullWidthResultsHitWrapper } from './Hits/styles'
import type { InStockProductsOverviewProps } from './types'
import TranslationContextProvider from './Context'
import ProductCard from '@/skeletons/Cards/Product'

const InStockProductsAlgoliaSearchOverview: React.FC<React.PropsWithChildren<InStockProductsOverviewProps>> = ({
  content,
}) => {
  const { noResultsText, noResultsTitle, placeholderResults, mobileFiltersTitle, filters } = content
  const noResultsContent = {
    title: noResultsTitle,
    text: noResultsText,
  }

  return (
    <TranslationContextProvider labels={content.defaultCardLabels}>
      <AlgoliaSearchOverview
        hitComponent={ProductCardHit}
        hitWrapperComponent={FullWidthResultsHitWrapper}
        skeletonLoader={ProductCard}
        facets={mapAlgoliaOverviewFilters(filters)}
        indexName="products"
        resultsPlaceholder={`${placeholderResults}: `}
        mobileFiltersTitle={mobileFiltersTitle}
        noResultsContent={noResultsContent}
        enableSearchbox={false}
        pageSize={5}
        defaultSearchOptions={{ filters: 'isInStock:true' }}
      />
    </TranslationContextProvider>
  )
}
export default InStockProductsAlgoliaSearchOverview
