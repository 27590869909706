import React from 'react'
import TranslationContextProvider from './Context'
import AlgoliaSearchOverview from './default'
import { mapAlgoliaOverviewFilters } from './Helpers/mapAlgoliaOverviewFilters'
import CharterProductHit from './Hit/usedProduct'
import { UsedProductsOverviewProps } from './types'
import CharterProductCard from '@/skeletons/Cards/charterProduct'

const AlgoliaOverview: React.FC<React.PropsWithChildren<UsedProductsOverviewProps>> = ({ content }) => {
  const { noResultsText, placeholderResults, mobileFiltersTitle, noResultsTitle, filters, title, totalResultsText } =
    content
  const noResultsContent = {
    title: noResultsTitle,
    text: noResultsText,
  }

  return (
    <TranslationContextProvider labels={null}>
      <AlgoliaSearchOverview
        hitComponent={CharterProductHit}
        skeletonLoader={CharterProductCard}
        facets={mapAlgoliaOverviewFilters(filters)}
        indexName="charterproducts"
        noResultsContent={noResultsContent}
        mobileFiltersTitle={mobileFiltersTitle}
        enableSearchbox={false}
        pageSize={18}
        title={{ title, totalResultsText }}
        resultsPlaceholder={`${placeholderResults}: `}
      />
    </TranslationContextProvider>
  )
}
export default AlgoliaOverview
