import React from 'react'

import { SkeletonLoader } from '@/components/UI'
import { Card, CardContentRow, ColumnWrapper } from '../../styles'
import { EventImageWrapper } from './styles'
import DataList from '../../DataList'
import TitleSkeleton from '../../Title'
import Spacer from '../../Spacer'
import Button from '../../Button'

interface Props {
  id: string | number
}

const EventCard: React.FC<Props> = ({ id }) => (
  <Card>
    <CardContentRow>
      <EventImageWrapper>
        <SkeletonLoader width="100%" height="272px" borderRadius={0} />
      </EventImageWrapper>
      <ColumnWrapper>
        <TitleSkeleton size="default" marginBottom="small" />
        <Spacer bottomMargin={14}>
          <SkeletonLoader width="100%" />
        </Spacer>
        <Spacer topMargin={14}>
          <DataList id={id} compact />
        </Spacer>
        <Spacer topMargin={24}>
          <Button />
        </Spacer>
      </ColumnWrapper>
    </CardContentRow>
  </Card>
)

export default EventCard
