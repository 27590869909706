import React from 'react'
import styled from 'styled-components'
import { theme } from '@damen/ui'
import type { StyledTabProps, Props as TabControlProps } from '@damen/ui/lib/esm/components/TabControl/Default'
import AlgoliaViewContextProvider, { useAlgoliaViewContext } from './Context/algoliaViewContext'
import TranslationContextProvider from './Context'
import type { ShiprepairYardOverviewProps } from './types'
import AlgoliaSearchOverview from './default'
import { mapAlgoliaOverviewFilters } from './Helpers/mapAlgoliaOverviewFilters'
import ShiprepairLocationCardHit from './Hit/shiprepairLocation'
import ViewToggle from './ViewToggle'
import BasicCard from '@/skeletons/Cards/basic'

const TabCompact = styled.button.withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
})<StyledTabProps>`
  position: relative;
  display: block;
  background: transparent;
  border: none;
  padding: 19px 0;
  font-family: ${theme.fonts.body.style.fontFamily};
  font-size: ${theme.typography.fontSizeText}px;
  font-weight: 500;
  color: ${({ active }) => (active ? theme.colors.marineBlack : theme.colors.grey)};

  &:not(:first-child) {
    margin-left: 40px;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }

  &:focus,
  &:hover {
    transition: all 0.2s ease-in-out;
    color: ${theme.colors.marineBlack};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10px;
    max-height: ${({ active }) => (active ? '4px' : '0')};
    transition: max-height 0.2s ease-in-out;

    ${({ active }) => {
      if (active) {
        return `
          background-color: ${theme.colors.marineBlack};
          `
      }
      return `background-color: ${theme.colors.white};`
    }}
  }
`

const ShiprepairYardOverviewWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <AlgoliaViewContextProvider>{children}</AlgoliaViewContextProvider>
)

const ShiprepairYardOverviewInner: React.FC<React.PropsWithChildren<ShiprepairYardOverviewProps>> = ({ content }) => {
  const {
    noResultsText,
    noResultsTitle,
    placeholderResults,
    mobileFiltersTitle,
    filters,
    title,
    listViewText,
    mapViewText,
    sortOrder,
  } = content
  const algoliaContext = useAlgoliaViewContext()
  const noResultsContent = {
    title: noResultsTitle,
    text: noResultsText,
  }

  const viewToggleArgs: TabControlProps = {
    defaultIndex: algoliaContext.viewMode === 'map' ? 1 : 0,
    lineColor: 'blue',
    display: 'default',
    tabComponent: ({ active, children, onClick }) => (
      <TabCompact active={active} onClick={onClick} type="button">
        {children}
      </TabCompact>
    ),
    tabcontrol: {
      tabs: [
        {
          id: 'tablist',
          title: listViewText,
          onClick: () => {
            algoliaContext.setViewMode('list')
          },
        },
        {
          id: 'tabmap',
          title: mapViewText,
          onClick: () => {
            algoliaContext.setViewMode('map')
          },
        },
      ],
    },
  }

  return (
    <TranslationContextProvider labels={content.defaultCardLabels}>
      <AlgoliaSearchOverview
        hitComponent={ShiprepairLocationCardHit}
        skeletonLoader={BasicCard}
        facets={mapAlgoliaOverviewFilters(filters)}
        rightComponent={() => <ViewToggle data={viewToggleArgs} />}
        indexName="locations"
        resultsPlaceholder={`${placeholderResults}: `}
        mobileFiltersTitle={mobileFiltersTitle}
        noResultsContent={noResultsContent}
        enableSearchbox={false}
        pageSize={100}
        defaultSearchOptions={{ filters: 'types:"Damen Shiprepair & Conversion"' }}
        title={{ title }}
        cmsSortOrder={sortOrder}
      />
    </TranslationContextProvider>
  )
}

const ShiprepairYardOverview: React.FC<React.PropsWithChildren<ShiprepairYardOverviewProps>> = ({ content }) => {
  return (
    <ShiprepairYardOverviewWrapper>
      <ShiprepairYardOverviewInner content={content} />
    </ShiprepairYardOverviewWrapper>
  )
}
export default ShiprepairYardOverview
