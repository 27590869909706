import React from 'react'
import { IntegratedServices as IntegratedServicesComponent } from '@/components/UI'
import { IntegratedServicesFragment } from '@/queries/fragments/IntegratedServicesFragment.generated'
import { renderRichText } from '@/utils/richTextUtils'

interface Props {
  content: IntegratedServicesFragment
}

const IntegratedServices = ({
  content: { integratedServicesTitle, subtitle, integratedServicesImage, integratedServicesLinkText, highlight },
}: Props) => {
  return (
    <IntegratedServicesComponent
      title={integratedServicesTitle}
      text={renderRichText(JSON.parse(subtitle))}
      linkText={integratedServicesLinkText}
      image={{
        src: integratedServicesImage.filename,
        alt: integratedServicesImage.alt,
      }}
      highlight={highlight.map((item) => ({
        id: item.id,
        title: item.title,
        subtitle: item.text,
        image: {
          src: item.image.filename,
          alt: item.image.alt,
        },
        buttons: item.hotspots.map((hotspot) => ({
          key: hotspot.id,
          x: hotspot.locationX,
          y: hotspot.locationY,
          title: hotspot.title,
          video: hotspot.video.filename,
          color: hotspot.color,
          highlight: hotspot.highlight.map((secondLevelItem) => ({
            id: secondLevelItem.id,
            title: secondLevelItem.title,
            subtitle: secondLevelItem.text,
            image: {
              src: secondLevelItem.image.filename,
              alt: secondLevelItem.image.alt,
            },
            buttons: secondLevelItem.hotspots.map((secondLevelHotspot) => ({
              key: secondLevelHotspot.id,
              x: secondLevelHotspot.locationX,
              y: secondLevelHotspot.locationY,
              title: secondLevelHotspot.title,
              video: secondLevelHotspot.video.filename,
              color: secondLevelHotspot.color,
            })),
          })),
        })),
      }))}
    />
  )
}

export default IntegratedServices
